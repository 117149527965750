import _objectSpread from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top"; // import washA from "./components/wash-a";

import washB from "./components/wash-b";
import washC from "./components/wash-c";
import washD from "./components/wash-d";
import washE from "./components/wash-e";
import washG from "./components/wash-g";
import { mapMutations } from "vuex"; // import washF from "./components/wash-f";

export default {
  metaInfo: {
    title: "智能洗涤管理方案",
    // set a title
    meta: [{
      // set meta
      name: "keyWords",
      content: "RFID洗涤管理方案 ,洗衣布草,RFID标签 超高频手持机 洗衣管理系统,常达"
    }, {
      name: "description",
      content: "随着RFID技术日益升级，RFID标签被广泛应用于各类洗衣场景中，并使传统手动记录洗衣过程转变成了全自动处理与记录洗衣过程。而且在洗涤产品上缝制RFID标签，可使用户利用RFID标签的全球唯一码自动识别和跟踪洗衣过程，并获取数据，方便用户后期指定更高质量的决策。"
    }]
  },
  data: function data() {
    return {
      list: [{
        image: require("@/assets/wash/swiper/1.jpg"),
        text: "标签缝制",
        imgOffset: "-30%",
        icon: require("@/assets/wash/swiper2/icon1.png"),
        icon1: require("@/assets/wash/swiper2/icon1c.png")
      }, {
        image: require("@/assets/wash/swiper/2.jpg"),
        text: "数据录入",
        imgOffset: "-41%",
        icon: require("@/assets/wash/swiper2/icon2.png"),
        icon1: require("@/assets/wash/swiper2/icon2c.png")
      }, {
        image: require("@/assets/wash/swiper/3.jpg"),
        text: "服饰洗涤",
        imgOffset: "-10%",
        icon: require("@/assets/wash/swiper2/icon3.png"),
        icon1: require("@/assets/wash/swiper2/icon3c.png")
      }, {
        image: require("@/assets/wash/swiper/4.jpg"),
        text: "布草晾晒",
        imgOffset: "-19%",
        icon: require("@/assets/wash/swiper2/icon4.png"),
        icon1: require("@/assets/wash/swiper2/icon4c.png")
      }, {
        image: require("@/assets/wash/swiper/5.jpg"),
        text: "布草分类打包",
        imgOffset: "-45%",
        icon: require("@/assets/wash/swiper2/icon5.png"),
        icon1: require("@/assets/wash/swiper2/icon5c.png")
      }, {
        image: require("@/assets/wash/swiper/6.jpg"),
        text: "交付运输",
        imgOffset: "-10%",
        icon: require("@/assets/wash/swiper2/icon6.png"),
        icon1: require("@/assets/wash/swiper2/icon6c.png")
      }, {
        image: require("@/assets/wash/swiper/7.jpg"),
        text: "最终用户",
        imgOffset: "-44%",
        icon: require("@/assets/wash/swiper2/icon7.png"),
        icon1: require("@/assets/wash/swiper2/icon7c.png")
      }],
      recommend: {
        title: "智能洗涤产品推荐",
        list: [{
          image: require("@/assets/wash/recommend/1.png"),
          to: "/product?name=RFID洗涤标签"
        }, {
          image: require("@/assets/wash/recommend/2.png"),
          to: "/product?name=RFID洗涤标签&current=1"
        }, {
          image: require("@/assets/wash/recommend/3.png"),
          to: "/product?name=RFID洗涤标签&current=2"
        }, {
          image: require("@/assets/wash/recommend/4.png"),
          to: "/product?name=手持机&current=3"
        }, {
          image: require("@/assets/wash/recommend/5.png"),
          to: "product?name=手持机&current=0"
        }]
      } // commonColumnSwiperOption: {
      //   paddingBottom: 100,
      //   defaultTop: 20,
      //   leftT: [],
      //   leftC: " ",
      //   list: [
      //     {
      //       bigImg: require("@/assets/wash/1.png"),
      //       lt: "标签缝制",
      //       lc: "标签ID与布草数据关联于数据库中",
      //     },
      //     {
      //       bigImg: require("@/assets/wash/2.png"),
      //       lt: "数据录入",
      //       lc: "布草交接登入",
      //     },
      //     {
      //       bigImg: require("@/assets/wash/3.png"),
      //       lt: "服饰洗涤",
      //       lc: "洗涤过程",
      //     },
      //     {
      //       bigImg: require("@/assets/wash/4.png"),
      //       lt: "服饰洗涤",
      //       lc: "洗净布草烘干、晾干",
      //     },
      //     {
      //       bigImg: require("@/assets/wash/5.png"),
      //       lt: "服饰洗涤",
      //       lc: "洗净布草分类、打包、发出",
      //     },
      //     {
      //       bigImg: require("@/assets/wash/6.png"),
      //       lt: "交付运输",
      //       lc: "",
      //     },
      //     {
      //       bigImg: require("@/assets/wash/7.png"),
      //       lt: "最终客户",
      //       lc: "",
      //     },
      //   ],
      // },

    };
  },
  components: {
    top: top,
    // washA,
    washB: washB,
    washC: washC,
    washD: washD,
    washE: washE,
    washG: washG // washF,

  },
  mounted: function mounted() {
    this.setHeaderColorWhite();
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"])),
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
  }
};