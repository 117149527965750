export default {
  data: function data() {
    return {
      current: 0,
      nav: [{
        title: "登录系统",
        list: [{
          t: "登录方式",
          image: require("@/assets/wash/g/login/1.png")
        }, {
          t: "首页功能展示",
          image: require("@/assets/wash/g/login/2.png")
        }]
      }, {
        title: "基础管理",
        list: [{
          t: "收脏地点",
          image: require("@/assets/wash/g/basis/1.png")
        }, {
          t: "设备管理",
          image: require("@/assets/wash/g/basis/2.png")
        }, {
          t: "布草产品",
          image: require("@/assets/wash/g/basis/3.png")
        }]
      }, {
        title: "内部运作",
        list: [{
          t: "进货单",
          image: require("@/assets/wash/g/inside/1.png")
        }, {
          t: "更换单",
          image: require("@/assets/wash/g/inside/2.png")
        }, {
          t: "盘点单",
          image: require("@/assets/wash/g/inside/3.png")
        }]
      }, {
        title: "布草洗涤",
        list: [{
          t: "送洗单",
          image: require("@/assets/wash/g/wash/1.png")
        }, {
          t: "洗净单",
          image: require("@/assets/wash/g/wash/2.png")
        }, {
          t: "收脏单",
          image: require("@/assets/wash/g/wash/3.png")
        }]
      }]
    };
  },
  computed: {
    list: function list() {
      var current = this.current,
          nav = this.nav;
      return nav[current].list || [];
    }
  }
};