export default {
  data: function data() {
    return {
      current: 0,
      list: [{
        t: "RFID工作服",
        c: "食品加工、贸易、服务、医疗保健各领域的工作服均可用此方案解决。",
        image: require("@/assets/wash/e/1.jpg")
      }, {
        t: "RFID酒店布草管理",
        c: "RFID方案可帮助用户更好地管理酒店布草库存和控制损失。",
        image: require("@/assets/wash/e/2.png")
      }, {
        t: "RFID垫子",
        c: "任何配有HF或UHF洗衣芯片的垫子都可利用RFID系统进行识别和管理。",
        image: require("@/assets/wash/e/3.png")
      }, {
        t: "RFID医疗布草",
        c: "手术衣裤管理是对使用的工作服、手术服等布类物资，在入库、出库、报损、污染、回收、清洁、分发等流程中进行统一管理。",
        image: require("@/assets/wash/e/4.png")
      }]
    };
  }
};