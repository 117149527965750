import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "wash-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_top = _resolveComponent("top");

  var _component_wash_b = _resolveComponent("wash-b");

  var _component_wash_c = _resolveComponent("wash-c");

  var _component_common_swiper2 = _resolveComponent("common-swiper2");

  var _component_wash_g = _resolveComponent("wash-g");

  var _component_wash_d = _resolveComponent("wash-d");

  var _component_wash_e = _resolveComponent("wash-e");

  var _component_common_recommend = _resolveComponent("common-recommend");

  var _component_common_footer1 = _resolveComponent("common-footer1");

  var _component_common_back_top = _resolveComponent("common-back-top");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_top), _createVNode(_component_wash_b), _createVNode(_component_wash_c), _createVNode(_component_common_swiper2, {
    list: $data.list,
    containerStyle: {
      background: '#F7F8FA'
    }
  }, null, 8, ["list"]), _createVNode(_component_wash_g), _createVNode(_component_wash_d), _createVNode(_component_wash_e), _createVNode(_component_common_recommend, {
    title: $data.recommend.title,
    list: $data.recommend.list
  }, null, 8, ["title", "list"]), _createElementVNode("div", {
    style: _normalizeStyle({
      margin: '0 0 .4rem'
    })
  }, null, 4), _createVNode(_component_common_footer1), _createVNode(_component_common_back_top)]);
}