export default {
  data: function data() {
    return {
      list: [{
        icon: require("@/assets/wash/d/1.png"),
        t: "追溯/跟踪",
        c: "快速识别和监控洗涤产品流通；使客户明确洗涤产品的损失率，保证采购数量准确率；便于客户实现洗涤产品零库存目标。"
      }, {
        icon: require("@/assets/wash/d/2.png"),
        t: "过程自动化",
        c: "更高的生产率和效率（减少供应链的交付周期）；脏污洗涤产品的处理效率大大提高；减少了手工劳动和非增值环节，有效节约了成本。"
      }]
    };
  }
};